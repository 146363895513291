// extracted by mini-css-extract-plugin
export var allLink = "pages-allLink--H7xLG";
export var articleList = "pages-article-list--m3Wlf";
export var headingH3 = "pages-heading--h3--ESn47";
export var listCategory = "pages-list-category--x99bm";
export var listCircles = "pages-list-circles--dNycK";
export var listInline = "pages-list-inline--VFa1v";
export var listPlain = "pages-list-plain--iWmAP";
export var listSmColumn = "pages-list-sm-column--NXP9E";
export var listTag = "pages-list-tag--50wv0";
export var mainContent = "pages-mainContent--bH6tR";
export var sectionHeadline = "pages-section-headline--VZ33I";
export var spacingVBLg = "pages-spacing-v-b-lg--3c2TZ";
export var spacingVBMd = "pages-spacing-v-b-md--ELN75";
export var spacingVBSm = "pages-spacing-v-b-sm--lacE4";
export var spacingVBZ = "pages-spacing-v-b-z--eJGqJ";
export var spacingVTLg = "pages-spacing-v-t-lg--HL4us";
export var spacingVTMd = "pages-spacing-v-t-md--CPm5C";
export var spacingVTSm = "pages-spacing-v-t-sm--lOBno";
export var spacingVTZ = "pages-spacing-v-t-z--Spn8o";
export var textLink = "pages-textLink--EycME";
export var uFlex = "pages-u-flex---UygQ";
export var uLg1of4 = "pages-u-lg-1of4--2vO+X";
export var uLg3of4 = "pages-u-lg-3of4--vOM5F";
export var wrapper = "pages-wrapper--UN8kG";